@tailwind base;
@tailwind components;
@tailwind utilities;

#vana {
    font-family: 'Gill Sans Nova Ultra Bold', Arial, sans-serif;
}

@layer base {
    html {
        @apply scroll-smooth
    }
    body {
        @apply bg-white
        dark:bg-gray-800
    }
    li {
        @apply p-4
        hover:text-fuchsia-600 hover:border-fuchsia-800 dark:text-white dark:hover:text-fuchsia-400 dark:hover:border-fuchsia-400
    }
    button[disabled] {
        @apply bg-fuchsia-900 hover:bg-fuchsia-900 dark:bg-fuchsia-900 dark:hover:bg-fuchsia-900
    }
}